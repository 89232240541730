import './bootstrap';

import { createRoot } from 'react-dom/client';
import { createInertiaApp } from '@inertiajs/react';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ToastsProvider as BootstrapToastsProvider } from 'react-bootstrap-toasts';
import {StrictMode} from "react";
import toastr from 'toastr';
import * as Sentry from "@sentry/react";

document.documentElement.setAttribute('data-theme', 'light');
toastr.options = {
    progressBar: true,
    preventDuplicates: true,
    closeButton: true
};

Sentry.init({
    dsn: "https://656449d57f6ace769123bc202eec5bef@sentry.pnp.digital/23",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});

(async () => {
    await createInertiaApp({
        title: (title) => `${title} - Soberistas`,
        resolve: async (name) => await resolvePageComponent(`./Pages/${name}.jsx`, import.meta.glob('./Pages/**/*.jsx')),
        setup({ el, App, props }) {
            const root = createRoot(el);

            root.render(
                <StrictMode>
                    <BootstrapToastsProvider toastContainerProps={{position: 'middle-center'}}>
                        <App {...props} />
                    </BootstrapToastsProvider>
                </StrictMode>
            );
        },
        progress: {
            color: '#2d5858',
        },
    });
})();
